<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-xxl">
      <!--begin::Contact-->
      <div class="card p-0">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column p-lg-17">
          <!--begin::Row-->
          <div class="d-flex w-100 justify-content-center mb-10">
            <h1>Privacy Policy</h1>
          </div>
          <div class="d-flex w-100 flex-column">
            <h3 class="font-weight-bold mb-5">Privacy Act</h3>

            <p>
              Our business is bound by the Privacy Act 1988 (the Act) and the
              Australian Privacy Principles (APP). Our business is an APP entity
              as defined in s 6(1) of the Act.
            </p>

            <p>
              We collect and hold personal information relating to our members
              and to other people and entities associated with our members as
              may be provided or disclosed to us in the course of business. Such
              personal information may include, but is not limited to, names,
              tax file numbers, addresses, telephone numbers, social media
              details, email addresses, occupations, tenancy records, and
              relationship details.
            </p>

            <p>
              We understand that protecting your personal information is
              important. This Privacy Policy sets out our commitment to
              protecting the privacy of personal information provided to us, or
              otherwise collected by us when providing our website platform to
              you where agents can share reviews and provide feedback about
              their tenants and other agents can view these reviews (Services).
              In this Privacy Policy we, us or our means Tenant Hub Pty Ltd ACN
              “______________” (Tenant Hub).
            </p>

            <p>
              Personal information is collected from our members and tenants in
              the following ways:
            </p>
            <ul>
              <li>by providing it to us directly;</li>
              <li>by authorising third parties to provide it to us;</li>
              <li>
                by other parties providing it to us either voluntarily or
                pursuant to compulsory processes we conduct on our member’s
                behalf.
              </li>
            </ul>

            <h5 class="font-weight-bold mb-3">
              How is personal information received and held?
            </h5>

            <p>
              Personal information may be received and held either as a hard
              copy, paper, or a soft copy being electronic data, in any
              available form. In either case, we take the security of personal
              information very seriously. We secure hard copy documents
              carefully in and out of our office. We use cyber- security systems
              to protect soft copy documents. We never ask for bank details or
              other sensitive information by email.
            </p>

            <h5 class="font-weight-bold mb-3">
              For what purpose is personal information collected, held, used and
              disclosed?
            </h5>

            <p>
              All data is processed by the business on a lawful basis. The
              purposes for which we collect, hold, use and disclose personal
              information are:
            </p>

            <ul>
              <li>
                to be used for the purposes of verification of your identity in
                tenancy applications;
              </li>
              <li>
                to be used for the purposes of risk assessment in tenancy
                applications;
              </li>
              <li>
                to be used for the purposes of a referral in tenancy
                applications;
              </li>
              <li>to comply with applicable laws.</li>
            </ul>

            <p>
              If you elect not to provide information to our members, they will
              not be able to properly assess the risk to their client in
              assessing a tenancy application submitted by you. Our member will
              not be able to carry out their duties as asset managers and
              consequently a tenancy application submitted by you may not be
              processed or accessed.
            </p>

            <p>
              Any government identifier that may be used by Tenant Hub is only
              used as an identifier and is not used for any other purpose.
              Tenant Hub does not collect any commonwealth identifiers.
            </p>

            <h5 class="font-weight-bold mb-3">
              For what purpose is personal information collected, held, used and
              disclosed?
            </h5>

            <p>
              Members and tenants may access their personal information and seek
              correction of it at any time by applying to our office in person
              or in writing.
            </p>

            <p>
              Members and tenants will be formally identified before releasing
              or amending any personal information.
            </p>

            <h5 class="font-weight-bold mb-3">
              What is the complaints process relating to personal information?
            </h5>

            <p>
              If there is a breach of this privacy policy, either of the Act or
              the Australian Privacy Principles (APP), a complaint may be made
              by the member or tenant to:
            </p>

            <ul>
              <li>our customer services team; or</li>
              <li>the Office of the Australian Privacy Commissioner.</li>
            </ul>

            <h5 class="font-weight-bold mb-3">Data breaches</h5>

            <p>
              Tenant Hub is responsible for the protection of your personal
              information from any misuse, loss and unauthorised access. Tenant
              Hub restricts access to your personal information to their members
              only and each member has been verified and identified by Tenant
              Hub.
            </p>

            <p>
              All staff and members are responsible for protecting the
              confidentiality of your information. Refer any data breaches, or
              suspected data breaches, to the customer services team as soon as
              possible.
            </p>

            <h5 class="font-weight-bold mb-3">
              What is an eligible data breach?
            </h5>
            <p>
              An eligible data breach, defined in s 26WE(2) of the Act, is when:
            </p>
            <ol style="list-style: none">
              <li>
                (a) both of the following conditions are satisfied:
                <ol style="list-style: none">
                  <li>
                    (i) there is unauthorised access to, or unauthorised
                    disclosure of, the information;
                  </li>
                  <li>
                    (ii) a reasonable person would conclude that the access or
                    disclosure would be likely to result in serious harm to any
                    of the individuals to whom the information relates; or
                  </li>
                </ol>
              </li>
              <li>
                (b) the information is lost in circumstances where:
                <ol style="list-style: none">
                  <li>
                    (i) unauthorised access to, or unauthorised disclosure of,
                    the information is likely to occur; and
                  </li>
                  <li>
                    (ii) assuming that unauthorised access to, or unauthorised
                    disclosure of, the information were to occur, a reasonable
                    person would conclude that the access or disclosure would be
                    likely to result in serious harm to any of the individuals
                    to whom the information relates;...
                  </li>
                </ol>
              </li>
            </ol>

            <h5 class="font-weight-bold mb-3">
              If there is a suspicion of a breach
            </h5>
            <p>
              If we suspect that there has been an eligible data breach, a
              reasonable and expeditious assessment will be conducted within 30
              days.
            </p>
            <p>
              If we believe or have reasonable grounds to believe there has been
              a breach then a statement will be prepared setting out:
            </p>

            <ul>
              <li>the member’s details;</li>
              <li>a description of the breach;</li>
              <li>the kind or kinds of information concerned; and</li>
              <li>
                recommendations about the steps that we will take in response to
                it.
              </li>
            </ul>

            <p>
              If practicable, we will advise the contents of the statement to
              each of the affected member and tenant who may be at risk from the
              breach. If this is not practicable, we will publish the statement
              on our website and take other reasonable steps to publicise its
              contents. Communications with individuals will be via their
              preferred communication method.
            </p>

            <p>The statement will be submitted to the Privacy Commissioner.</p>

            <h5 class="font-weight-bold mb-3">Exception to reporting</h5>
            <p>
              Mandatory notification requirements are waived if remedial action
              can be taken that results in a reasonable person concluding that
              the access or disclosure is not likely to result in serious harm
              to any of those individuals.
            </p>

            <h5 class="font-weight-bold mb-3">Your Choice</h5>
            <p>
              Please read this Privacy Policy carefully. If you provide personal
              information to us, you understand we will collect, hold, use and
              disclose your personal information in accordance with this Privacy
              Policy. You do not have to provide personal information to us,
              however, if you do not, it may affect our ability to provide our
              members the ability to access your tenancy application.
            </p>

            <h5 class="font-weight-bold mb-3">Complaints</h5>
            <p>
              If you wish to make a complaint, please contact us using the
              details below and provide us with full details of the complaint.
              We will promptly investigate your complaint and respond to you, in
              writing, setting out the outcome of our investigation and steps we
              will take in response to your complain. You also have the right to
              contact the relevant privacy authority.
            </p>

            <h5 class="font-weight-bold mb-3">Cookies</h5>
            <p>
              We may use cookies on our online Services from time to time.
              Cookies are text files place in your computer’s browser to store
              your preferences. Cookies, by themselves, do not tell us your
              email address or other personally identifiable information.
              However, they do recognise you when you return to our online
              Services and allow third parties, such as Google and Facebook, to
              cause our advertisements to appear on your social media and online
              media fees as part of our retargeting campaigns. If and when you
              choose to provide our online Services with personal information
              this information way be linked to the data stored in the cookie.
            </p>

            <p>
              You can block cookies by activating the setting on your browser
              that allows you to refuse the setting of all or some cookies.
              However, if you use your browser settings to block all cookies
              (including essential cookies) you may not be able to access all or
              part of our online service.
            </p>

            <h5 class="font-weight-bold mb-3">Links to other websites</h5>
            <p>
              Our Services may contain links to other websites. We do not have
              any control over those websites and we are not responsible for the
              protection and privacy of any personal information which you
              provide whilst visiting those websites. Those websites are not
              governed by this Privacy Policy.
            </p>

            <h5 class="font-weight-bold mb-3">Amendments</h5>
            <p>
              We may, at any time and at our discretion, vary this Privacy
              Policy by publishing the amended Privacy Policy on our website. We
              recommend you check our website regularly to ensure you are aware
              of our current Privacy Policy.
            </p>

            <h5 class="font-weight-bold mb-3">Questions & Contact</h5>
            <p>
              If you have any question and is required to contact us, please
              contact Tenant Hub ACN “____________” on Email: -
              support@tenanthub.au.
            </p>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Contact-->
    </div>
    <!--end::Content container-->

    <!-- <GoogleRecaptcha /> -->
  </div>
</template>
